import type {FC} from 'react'
import LinearProgress from '@mui/material/LinearProgress'
import {useSelector} from 'react-redux'
import {useSearchParams} from 'react-router-dom'
import ConnectionOptionCardTable from '../../components/connections/addConnectionForm/ConnectionOptionCardTable'
import AddConnectionValidatorForm from '../../components/connections/addConnectionForm/AddConnectionValidatorForm'
import AddConnectionUpdateDetails from '../../components/connections/addConnectionForm/AddConnectionUpdateDetails'
import DocumentationButton from '../../shared/documentationButton'
import AddConnectionEdgeApiNoConnectionButtons from '../../components/connections/AddConnectionEdgeApiNoConnectionButtonsF'
import ConnectivitySyncBox from '../../shared/ConnectivitySyncBoxF'
import MQTTDataCard from '../../components/connections/addConnectionForm/MQTTDataCard'
import type {RootState} from '../../../../App/store'

interface Props {
    stepIndex: number,
}

const StepContentEdgeApiForm:FC<Props> = ({stepIndex}) => {
	const {data, mqttData} = useSelector((state: RootState) => state.edgeApiConnectionForm)
	const {onlineThreeMinutes} = useSelector((state: RootState) => state.edgeApiNavigationForm)
	const [searchParams] = useSearchParams()
	const deviceTypeUrl = searchParams.get('device_type')

	switch (stepIndex) {
		case 0:
			return (
				<ConnectionOptionCardTable />
			)
		case 1:
			return deviceTypeUrl === 'factor_201' ? (
				<AddConnectionValidatorForm />
			) : (
				<AddConnectionUpdateDetails />
			)
		case 2:
			return deviceTypeUrl === 'own_mqtt_publisher' && mqttData ? (
				<MQTTDataCard
					mqttData={mqttData}
				/>
			) : data && deviceTypeUrl === 'factor_201' ? (
				<AddConnectionUpdateDetails />
			) : null
		case 3:
			return <DocumentationButton />
		case 4:
			return <DocumentationButton />
		case 5:
			return (
				<>
					<LinearProgress sx={
						{marginBottom: 1}
					}
					/>
					{onlineThreeMinutes === false ? <AddConnectionEdgeApiNoConnectionButtons /> : null}
				</>
			)
		case 6:
			return <ConnectivitySyncBox />
		default:
			return ''
	}
}

export default StepContentEdgeApiForm
