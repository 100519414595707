import {useNavigate} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import type {AuthResponseInterface} from '../interfaces/auth'
import type {UserInterface} from '../interfaces/user'
import {setPreferredLanguage, setPreferredTheme} from '../../redux/slices/user'
import type {UserContextProps} from '../../containers/App/UserProvider'
import {useUserContext} from '../../containers/App/UserProvider'

interface AuthHookInterface {
	loginUser: (creds: AuthResponseInterface) => void,
	logoutUser: () => void,
	isUserLoggedIn: () => boolean,
	updateLocalUser: (user: UserInterface) => void,
	auth: string | null,
}

export const useAuth = (): AuthHookInterface => {
	const {setCurrentUser, setLoadingUser} = useUserContext() as UserContextProps
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const auth = localStorage.getItem('token') ? localStorage.getItem('token') : null
	const loginUser = (creds: AuthResponseInterface): void => {
		localStorage.setItem('token', creds.token)
		if (creds.user) {
			setCurrentUser(creds.user)
			if (creds.user.userprofile) {
				dispatch(setPreferredTheme(creds.user.userprofile.preferred_theme))
				dispatch(setPreferredLanguage(creds.user.userprofile.preferred_language))
			}
		}
		setTimeout(() => {
			navigate('/')
			setLoadingUser(false)
		}, 2000)
	}
	const logoutUser = (): void => {
		localStorage.removeItem('token')
		setCurrentUser(null)
		navigate('/login')
	}
	const isUserLoggedIn = (): boolean => !!auth
	const updateLocalUser = (user: UserInterface): void => {
		setCurrentUser(user)
	}
	return {
		loginUser, logoutUser, isUserLoggedIn, updateLocalUser, auth,
	}
}
