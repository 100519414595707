import type {GridRowId, GridRowSelectionModel} from '@mui/x-data-grid'
import {baseApi} from './base'
import type {BaseFetchResultsInterface} from '../shared/interfaces/base'
import type {EquipmentInterface} from '../shared/interfaces/equipment.ts'
import type {EquipmentWithLineNameInterface} from '../shared/interfaces/equipmentWithLineName.ts'
import type {ProgramInterface} from '../shared/interfaces/program.ts'
import type {TriggerInterface} from '../shared/interfaces/trigger.ts'
import type {ProgramDetectorInterface} from '../shared/interfaces/programDetector.ts'

const lineManagementAPI = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		getEquipments: builder.query<EquipmentInterface[], { lineId: string}>({
			query: ({lineId}) => ({url: `machinesubsystems/?machine=${lineId}&limit=500`}),
			extraOptions: {refetchOnMountOrArgChange: true},
			transformResponse: (response: BaseFetchResultsInterface<EquipmentInterface>) => response.results,
		}),
		getEquipment: builder.query<EquipmentInterface, { equipmentId: string}>({
			query: ({equipmentId}) => ({url: `machinesubsystems/${equipmentId}/`}),
			extraOptions: {refetchOnMountOrArgChange: true},
		}),
		getEquipmentsWithLineName: builder.query<EquipmentWithLineNameInterface[], {
			limit: number,
			lineId?: string,
		}>({
			query: ({limit, lineId}) => ({url: `machinesubsystems/with_machine_name/?limit=${limit}${lineId ? `&machine=${lineId}` : ''}`}),
			extraOptions: {refetchOnMountOrArgChange: true},
			transformResponse: (response: BaseFetchResultsInterface<EquipmentWithLineNameInterface>) => response.results,
		}),
		getPrograms: builder.query<ProgramInterface[], {
			lineId?: string,
			equipmentId?: string,
			limit?: number,
		}>({
			query: ({
				lineId,
				equipmentId,
				limit,
			}) => `programs/?machine=${lineId}${equipmentId ? `&machine_sub_system=${equipmentId}` : ''}&limit=${limit}`,
			transformResponse: (response: BaseFetchResultsInterface<ProgramInterface>) => response.results,
		}),
		updateEquipment: builder.mutation<{ message: string, result: EquipmentInterface }, { equipmentId: string | GridRowId, method:string, body: { name?: string, description?: string, geojson_data?: string} | FormData }>({
			query: ({
				equipmentId, method, body,
			}) => ({
				url: `machinesubsystems/${equipmentId ? `${equipmentId}/` : ''}`,
				method,
				body,
			}),
		}),
		updateProgram: builder.mutation<{ message: string, result: ProgramInterface }, { programId: string, method: string, body: { name?: string, description?: string, classification?: string, step_signal?: string, anomaly_detection_enabled?:boolean } | FormData }>({
			query: ({
				programId, method, body,
			}) => ({
				url: `programs/${programId}`,
				method,
				body,
			}),
		}),
		updateProgramDetectorTrigger: builder.mutation<{ message: string, result: TriggerInterface }, { programId: string, method: string, body: FormData }>({
			query: ({
				programId, method, body,
			}) => ({
				url: `programdetectortriggers/${programId}`,
				method,
				body,
			}),
		}),
		updateProgramDetectors: builder.mutation<{ message: string, result: ProgramDetectorInterface }, { programId: string, body: { triggers: string[], triggers_truth_eval: string } }>({
			query: ({programId, body}) => ({
				url: `programdetectors/${programId}/`,
				method: 'PATCH',
				body,
			}),
		}),
		updateProgramTriggersDelete: builder.mutation<{ message: string }, { triggerId: string, method: string }>({
			query: ({triggerId, method}) => ({
				url: `programdetectortriggers/${triggerId}/`,
				method,
			}),
		}),
		updateEquipmentAddSignal: builder.mutation<{ message: string }, { equipmentId: string, body:{signals_to_update: GridRowSelectionModel}}>({
			query: ({equipmentId, body}) => ({
				url: `machinesubsystems/${equipmentId}/`,
				method: 'PATCH',
				body,
			}),
		}),
		updateEquipmentRemoveSignal: builder.mutation<{message: string}, {signals: string[]}>({
			query: ({signals}) => ({
				url: 'signals/unassign_equipment/',
				method: 'POST',
				body: {signal_ids: signals},
			}),
		}),
		deleteSignal: builder.mutation<{ message: string }, { signal_id: string }>({
			query: ({signal_id}) => ({
				url: `signals/${signal_id}/`,
				method: 'DELETE',
			}),
		}),
		deleteEquipment: builder.mutation<{ message: string }, { machine_sub_system_id: string }>({
			query: ({machine_sub_system_id}) => ({
				url: `machinesubsystems/${machine_sub_system_id}/`,
				method: 'DELETE',
			}),
		}),
		deleteLine: builder.mutation<{ message: string }, { line_id: string }>({
			query: ({line_id}) => ({
				url: `machines/${line_id}/`,
				method: 'DELETE',
			}),
		}),
	}),
	overrideExisting: false,
})

export const {
	useGetEquipmentsQuery,
	useGetEquipmentQuery,
	useUpdateEquipmentMutation,
	useUpdateProgramMutation,
	useUpdateProgramDetectorTriggerMutation,
	useUpdateProgramDetectorsMutation,
	useDeleteSignalMutation,
	useUpdateProgramTriggersDeleteMutation,
	useUpdateEquipmentAddSignalMutation,
	useDeleteEquipmentMutation,
	useGetProgramsQuery,
	useLazyGetProgramsQuery,
	useGetEquipmentsWithLineNameQuery,
	useUpdateEquipmentRemoveSignalMutation,
	useDeleteLineMutation,
} = lineManagementAPI
