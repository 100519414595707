import type {
	ChangeEvent, FC, KeyboardEvent,
} from 'react'
import {
	useEffect, useRef, useState,
} from 'react'
import {Box, ClickAwayListener} from '@mui/material'
import {AutoAwesome} from '@mui/icons-material'
import {useLocation} from 'react-router-dom'
import BasicInput from '../../../shared/components/BasicInput.tsx'
import {useCustomTranslation} from '../../../shared/hooks/useCustomTranslation.ts'
import AppbarSearchStandard from './AppbarSearchStandard.tsx'

const AppbarSearch: FC = () => {
	const [searchTerm, setSearchTerm] = useState<string>('')
	const [finalSearchTerm, setFinalSearchTerm] = useState<string>('')
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const inputRef = useRef<null | HTMLDivElement>(null)
	const searchFieldRef = useRef<null | HTMLDivElement>(null)

	const [t] = useCustomTranslation('common')
	const location = useLocation()

	const handlePopperClose = (): void => {
		setAnchorEl(null)
	}

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search)
		const globalSearchTerm = searchParams.get('global_search_term')
		if (globalSearchTerm) {
			setSearchTerm(globalSearchTerm)
			setFinalSearchTerm(globalSearchTerm)
			setAnchorEl(searchFieldRef.current)
		}
	}, [location.search])

	useEffect(() => {
		const handleKeyDown = (event: globalThis.KeyboardEvent): void => {
			// Checking for CTRL on Windows anc CMD on Mac
			if ((event.ctrlKey || event.metaKey) && event.key === 'k') {
				event.preventDefault()
				setAnchorEl(searchFieldRef.current)
				inputRef.current?.focus()
			}

			// Close popper on ESC key press and remove focus from input
			if (event.key === 'Escape') {
				handlePopperClose()
				inputRef.current?.blur()
			}
		}

		document.addEventListener('keydown', handleKeyDown)
		return () => {
			document.removeEventListener('keydown', handleKeyDown)
		}
	}, [])

	const handleSearchChange = (event: ChangeEvent<HTMLInputElement>): void => {
		setSearchTerm(event.target.value)
		// If search term is empty reset finalSearchTerm
		if (!event.target.value) {
			setFinalSearchTerm('')
		}
	}

	const performSearch = (): void => {
		setFinalSearchTerm(searchTerm)
	}

	const handleSearchClick = (): void => {
		setAnchorEl(searchFieldRef.current)
		performSearch()
	}

	const handleSearchKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
		if (event.key === 'Enter') {
			setAnchorEl(searchFieldRef.current)
			performSearch()
		}
	}

	const open = Boolean(anchorEl)
	const searchBarWidth = searchFieldRef.current ? searchFieldRef.current.offsetWidth : 0

	return (
		<ClickAwayListener onClickAway={handlePopperClose}>
			<Box>
				<Box
					ref={searchFieldRef}
					sx={
						{display: 'flex', alignItems: 'center'}
					}
				>

					<BasicInput
						data-testid="mbai-appbar-search-component"
						data-cy="mbai-main-omni-search-component"
						id="mbai-appbar-search-component"
						placeholder={t('appbar_search')}
						value={searchTerm}
						InputProps={
							{
								startAdornment: (
									<AutoAwesome
										sx={
											{
												fontSize: '1.25rem',
												color: 'main.secondary',
											}
										}
									/>
								),
							}
						}
						inputRef={inputRef}
						onClick={handleSearchClick}
						onChange={handleSearchChange}
						onKeyDown={handleSearchKeyDown}
						defaultValue=""
						mbaiSize="medium"
						displayLabel={false}
					/>
				</Box>
				{
					open ? (
						<AppbarSearchStandard
							anchorEl={anchorEl}
							minWidth={searchBarWidth}
							handleClose={handlePopperClose}
							searchTerm={finalSearchTerm}
						/>
					) : null
				}
			</Box>
		</ClickAwayListener>
	)
}

export default AppbarSearch
