import type {
	ReactElement,
	MouseEventHandler,
} from 'react'
import {useSearchParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {Box, Button} from '@mui/material/'
import {LoadingButton} from '@mui/lab'
import {setIdentifier} from '../../redux/slices/edgeApiConnectionFormSlice'
import {
	setSteps, decrementActiveStep, incrementActiveStep,
	setValidate,
} from '../../redux/slices/edgeApiNavigationFormSlice'
import type {RootState} from '../../../../App/store'
import {useSteps} from '../stepsEdgeApi'
import {useCustomTranslation} from '../../../../../shared/hooks/useCustomTranslation'

interface Step {
    description?: string,
    label: string,
}

interface StepNavigationHookInterface {
	handleBack: () => void,
	nextOrFinish: (mode: string | null) => string | null,
	backButton: (steps: Step[]) => boolean,
	handleNext: () => void,
	buttonsToRender: (handleAddConnection: MouseEventHandler<HTMLButtonElement> | undefined, handleCreateMQTT: MouseEventHandler<HTMLButtonElement> | undefined) => ReactElement,
}

const UseStepNavigation = (): StepNavigationHookInterface => {
	const [searchParams, setSearchParams] = useSearchParams()
	const {activeStep, steps} = useSelector((state: RootState) => state.edgeApiNavigationForm)
	const stepLibrary = useSteps()
	const dispatch = useDispatch()
	const [t] = useCustomTranslation('edge_api')
	const mode = searchParams.get('mode')
	const {identifier} = useSelector((state: RootState) => state.edgeApiConnectionForm)

	const handleBack = (): void => {
		const newSearchParams = new URLSearchParams(searchParams)
		if (activeStep === 2) {
			newSearchParams.delete('identifier')
			newSearchParams.delete('id')
			setIdentifier('')
		} else if (activeStep === 1) {
			newSearchParams.delete('device_type')
			newSearchParams.delete('identifier')
			newSearchParams.delete('id')
			dispatch(setSteps(stepLibrary.stepFirst))
		}
		setSearchParams(newSearchParams)
		dispatch(decrementActiveStep())
	}

	const nextOrFinish = (mode: string | null): string | null => {
		const deviceTypeUrl = searchParams.get('device_type')
		if (activeStep === 5 || activeStep === 0 || !deviceTypeUrl) {
			return null
		}
		if (activeStep === 6) {
			return mode === 'desktop' ? 'Finish' : null
		}
		if (activeStep === 1) {
			return deviceTypeUrl === 'factor_201' ? 'Validate' : 'Create'
		}
		if (activeStep === 2 && deviceTypeUrl === 'own_mqtt_publisher') {
			return 'Finish'
		}
		return 'Next'
	}

	const backButton = (steps: Step[]): boolean => {
		const deviceTypeUrl = searchParams.get('device_type')
		if (deviceTypeUrl === 'factor_201') {
			return activeStep !== steps.length - 1 && activeStep !== 3
		}
		if (deviceTypeUrl === 'own_mqtt_publisher') {
			return activeStep === 1
		}
		return false
	}

	const handleNext = (): void => {dispatch(incrementActiveStep())}

	const buttonsToRender = (handleAddConnection: MouseEventHandler<HTMLButtonElement> | undefined, handleCreateMQTT: MouseEventHandler<HTMLButtonElement> | undefined): ReactElement => (
		<Box sx={
			{
				display: 'flex', flexDirection: 'row', pt: 2,
			}
		}
		>
			{
				backButton(steps) ? (
					<Button
						color="inherit"
						disabled={activeStep === 0}
						onClick={handleBack}
						sx={
							{mr: 1}
						}
					>
						{t('buttons.back')}
					</Button>
				) : null
			}
			<Box sx={
				{flex: '1 1 auto'}
			}
			/>
			{
				nextOrFinish(mode) === 'Finish' ? (
					<LoadingButton
						variant="contained"
						color="primary"
						onClick={() => {window.location.href = searchParams.get('device_type') === 'own_mqtt_publisher' ? '/connections?tab=mqtt_connections' : '/connections?tab=edge_device'}}
					>
						{t('buttons.finish')}
					</LoadingButton>
				) : nextOrFinish(mode) === 'Next' ? (
					<Button
						variant="contained"
						onClick={activeStep === 2 ? handleAddConnection : handleNext}
					>
						{t('buttons.next')}
					</Button>
				) : nextOrFinish(mode) === 'Validate' ? (
					<Button
						variant="contained"
						disabled={!identifier}
						onClick={() => dispatch(setValidate(true))}
					>
						{t('buttons.validate')}
					</Button>
				) : nextOrFinish(mode) === 'Create' ? (
					<Button
						variant="contained"
						onClick={handleCreateMQTT}
					>
						{t('buttons.create')}
					</Button>
				) : null
			}
		</Box>
	)

	return {
		handleBack,
		nextOrFinish,
		backButton,
		handleNext,
		buttonsToRender,
	}
}

export default UseStepNavigation
