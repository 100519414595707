import type {Theme, ThemeOptions} from '@mui/material/styles'
import shadows from './shadow.ts'

export const sharedTheme: ThemeOptions = {
	shadows,
	typography: {
		h1: {
			fontFamily: 'Circular Black',
			fontSize: '2em',
			marginTop: '16px',
			marginBottom: '16px',
		},
		h2: {
			fontFamily: 'Circular Bold',
			fontSize: '1.5em',
		},
		h3: {
			fontFamily: 'Circular Bold',
			fontSize: '1.17em',
		},
		h4: {
			fontFamily: 'Circular Bold',
			fontSize: '1em',
		},
		h5: {
			fontFamily: 'Circular Bold',
			fontSize: '.83em',
		},
		h6: {
			fontFamily: 'Circular Bold',
			fontSize: '.67em',
		},
		body1: {fontFamily: 'Circular Medium'},
		body2: {fontFamily: 'Circular Medium'},
		subtitle1: {fontFamily: 'Circular Book'},
		subtitle2: {fontFamily: 'Circular Book'},
		fontFamily: [
			'Circular Medium',
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(','),
	},
	shape: {borderRadius: 7},
	components: {
		MuiCard: {
			defaultProps: {
				elevation: 1,
				sx: {
					'& .MuiCardHeader-root': {
						borderBottom: 1,
						borderColor: 'divider',
					},
					'& .MuiCardActions-root': {
						borderTop: 1,
						borderColor: 'divider',
						p: 2,
					},
				},
			},
		},
		MuiPaper: {
			defaultProps: {
				elevation: 0,
				sx: {
					'& .MuiCardHeader-root': {
						borderBottom: 1,
						borderColor: 'divider',
					},
					'& .MuiCardActions-root': {
						borderTop: 1,
						borderColor: 'divider',
						p: 2,
					},
				},
			},
		},
		MuiTooltip: {
			styleOverrides: {
				// Name of the slot
				tooltip: {
					backgroundColor: '#000000',
					borderRadius: '3px',
				},
				arrow: {'&::before': {backgroundColor: '#000000'}},
			},
			defaultProps: {arrow: true},
		},
		MuiButton: {
			styleOverrides: {
				// Name of the slot
				root: {
					// Some CSS
					whiteSpace: 'nowrap',
					textTransform: 'capitalize',
				},
			},
			defaultProps: {disableElevation: true},
		},
		MuiTablePagination: {defaultProps: {sx: {'& p': {mb: 0}}}},
		MuiDialogTitle: {
			styleOverrides: {
				// Name of the slot
				root: {
					// Some CSS
					fontSize: '1.2em',
				},
			},
		},
		MuiTab: {
			styleOverrides: {
				// Name of the slot
				root: {
					// Some CSS
					textTransform: 'none',
				},
			},
		},
		MuiCssBaseline: {
			styleOverrides: (themeParam: Theme) => `
            a {
            	text-decoration: none;
                color: ${themeParam.palette.primary.main};
            }
            a:hover {
                color: ${themeParam.palette.secondary.main};
            }
            input::-webkit-credentials-auto-fill-button {
                background-color: ${themeParam.palette.primary.main} !important;
            }
            .introjs-tooltipReferenceLayer * {
                font-family: ${themeParam.typography.fontFamily};
            }
            .mbaiTutorialTooltip {
                background-color: ${themeParam.palette.background.default};
                overflow: hidden;
            }
            .introjs-tooltip-header {
                border-bottom-width: 1px;
                border-bottom-style: solid;
                border-bottom-color: ${themeParam.palette.divider};
                padding-bottom: 12px;
                background-color: ${themeParam.palette.background.default};
            }
            .introjs-tooltipbuttons {
                border-top-width: 1px;
                border-top-style: solid;
                border-top-color: ${themeParam.palette.divider};
            }
            .introjs-prevbutton,
            .introjs-nextbutton {
                padding: 2px 16px;
                border: 1px solid ${themeParam.palette.primary.main};
                border-radius: 7px;
            }
            .introjs-prevbutton.introjs-disabled,
            .introjs-nextbutton.introjs-disabled {
                background: transparent;
                border: 1px solid ${themeParam.palette.divider};
            },
          `,
		},
	},
}
